<template>
  <div class="detail_container">
    <van-nav-bar
      title="护理员详情页"
      @click-left="$router.go(-1)"
      left-arrow
      safe-area-inset-top
      placeholder
    />
    <span class="span">护理员个人资料</span>
    <van-cell-group>
      <van-cell title="护理员画像" is-link value="查看" @click="showPopup" />
      <van-popup v-model="show">
        <van-image
          width="10rem"
          height="10rem"
          fit="contain"
          :src="dataList.avatar"
        />
      </van-popup>
      <van-cell title="姓名" :value="dataList.name" />

      <van-cell title="年龄" :value="dataList.age" />
      <van-cell
        title="身高"
        :value="dataList.stature == null ? '暂未填写' : `${dataList.stature}cm`"
      />
      <van-cell title="性别" :value="dataList.sex_dictText" />
      <van-cell
        title="工作经验"
        :value="
          dataList.workYears == 'null' ? '暂未填写' : `${dataList.workYears}年`
        "
      />
      <van-cell
        title="体重"
        :value="dataList.weight == null ? '暂未填写' : `${dataList.weight}kg`"
      />
      <van-cell
        title="籍贯"
        :value="
          dataList.censusRegisterGroundFullAddress == null
            ? '暂未填写'
            : dataList.censusRegisterGroundFullAddress
        "
      />
    </van-cell-group>
    <span class="span">相关资质</span>
    <div class="person papers" v-if="qualification.length == 0">
      <span>暂未填写</span>
    </div>
    <div class="person papers" v-else>
      <span v-for="(items, indexs) of qualification" :key="indexs">
        <span>{{ `《${items}》` }}</span
        ><br />
      </span>
    </div>
    <span class="span">擅长服务</span>

    <div class="small_con base_666_color">
      <div class="person skill base_color2" v-if="dataList.tags == null">
        <span>暂未填写</span>
      </div>
      <div class="person skill base_color2" v-else>
        <span v-for="(item, index) of dataList.tags" :key="index">{{
          item
        }}</span>
      </div>
    </div>
    <span class="span">自我介绍</span>
    <div class="small_con base_666_color">
      <div class="person">
        <div class="dotted">
          {{
            dataList.selfIntroduction == null
              ? "暂未填写"
              : dataList.selfIntroduction
          }}
        </div>
      </div>
    </div>

    <span class="span">工作经验</span>

    <div class="small_con baseColor">
      <div class="person">
        <div
          class="job"
          v-if="
            dataList.workExperienceList.length == 0
              ? true
              : dataList.workExperienceList[0].workContent == null
              ? true
              : false
          "
        >
          <span class="job_time">暂未填写</span>
        </div>

        <div class="job" v-else>
          <block
            v-for="(item, index) of dataList.workExperienceList"
            :key="index"
          >
            <span class="job_time"
              >{{ item.workStartDate }}~{{ item.workEndDate }}</span
            >
            <span class="job_con">
              {{ item.workContent }}
            </span>
          </block>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNurseDetailID } from "@/api/index.js";
export default {
  name: "",
  components: {},
  data() {
    return {
      dataList: null,
      qualification: [],
      show: false,
    };
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getNurseDetail();
  },
  methods: {
    showPopup() {
      this.show = true;
    },
    async getNurseDetail() {
      let res = await getNurseDetailID({
        id: this.$route.query.id,
      });

      this.dataList = res.data.result.records[0];
      if (this.dataList.qualificationCertificate !== null) {
        this.qualification = this.dataList.qualificationCertificate.split(",");
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-nav-bar {
  background: #0dacf4;

  .van-nav-bar__title {
    color: #fff;
  }
}

/deep/ .van-nav-bar__left {
  .van-icon {
    color: #fff;
  }
}

.span {
  font-size: 18px;
  margin-left: 15px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  display: inline-block;
}

.person {
  width: 100%;
  span {
    width: 80px;
    height: 31px;
    padding: 8px;
    background: #e7f8ff;
    border-radius: 2px;
    font-size: 14px;
    margin: 0 10px;
  }
}
.dotted {
  margin-left: 18px;
  font-size: 18px;
  color: rgb(113, 111, 111);
  margin-top: 12px;
}
.job {
  width: 100%;
  background: #e7f8ff;
  .job_time {
  }
  .job_con {
    display: block;
    width: 100%;
  }
}
.baseColor {
  margin-bottom: 40px;
}
</style>
