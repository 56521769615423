var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail_container"},[_c('van-nav-bar',{attrs:{"title":"护理员详情页","left-arrow":"","safe-area-inset-top":"","placeholder":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('span',{staticClass:"span"},[_vm._v("护理员个人资料")]),_c('van-cell-group',[_c('van-cell',{attrs:{"title":"护理员画像","is-link":"","value":"查看"},on:{"click":_vm.showPopup}}),_c('van-popup',{model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('van-image',{attrs:{"width":"10rem","height":"10rem","fit":"contain","src":_vm.dataList.avatar}})],1),_c('van-cell',{attrs:{"title":"姓名","value":_vm.dataList.name}}),_c('van-cell',{attrs:{"title":"年龄","value":_vm.dataList.age}}),_c('van-cell',{attrs:{"title":"身高","value":_vm.dataList.stature == null ? '暂未填写' : ((_vm.dataList.stature) + "cm")}}),_c('van-cell',{attrs:{"title":"性别","value":_vm.dataList.sex_dictText}}),_c('van-cell',{attrs:{"title":"工作经验","value":_vm.dataList.workYears == 'null' ? '暂未填写' : ((_vm.dataList.workYears) + "年")}}),_c('van-cell',{attrs:{"title":"体重","value":_vm.dataList.weight == null ? '暂未填写' : ((_vm.dataList.weight) + "kg")}}),_c('van-cell',{attrs:{"title":"籍贯","value":_vm.dataList.censusRegisterGroundFullAddress == null
          ? '暂未填写'
          : _vm.dataList.censusRegisterGroundFullAddress}})],1),_c('span',{staticClass:"span"},[_vm._v("相关资质")]),(_vm.qualification.length == 0)?_c('div',{staticClass:"person papers"},[_c('span',[_vm._v("暂未填写")])]):_c('div',{staticClass:"person papers"},_vm._l((_vm.qualification),function(items,indexs){return _c('span',{key:indexs},[_c('span',[_vm._v(_vm._s(("《" + items + "》")))]),_c('br')])}),0),_c('span',{staticClass:"span"},[_vm._v("擅长服务")]),_c('div',{staticClass:"small_con base_666_color"},[(_vm.dataList.tags == null)?_c('div',{staticClass:"person skill base_color2"},[_c('span',[_vm._v("暂未填写")])]):_c('div',{staticClass:"person skill base_color2"},_vm._l((_vm.dataList.tags),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item))])}),0)]),_c('span',{staticClass:"span"},[_vm._v("自我介绍")]),_c('div',{staticClass:"small_con base_666_color"},[_c('div',{staticClass:"person"},[_c('div',{staticClass:"dotted"},[_vm._v(" "+_vm._s(_vm.dataList.selfIntroduction == null ? "暂未填写" : _vm.dataList.selfIntroduction)+" ")])])]),_c('span',{staticClass:"span"},[_vm._v("工作经验")]),_c('div',{staticClass:"small_con baseColor"},[_c('div',{staticClass:"person"},[(
          _vm.dataList.workExperienceList.length == 0
            ? true
            : _vm.dataList.workExperienceList[0].workContent == null
            ? true
            : false
        )?_c('div',{staticClass:"job"},[_c('span',{staticClass:"job_time"},[_vm._v("暂未填写")])]):_c('div',{staticClass:"job"},_vm._l((_vm.dataList.workExperienceList),function(item,index){return _c('block',{key:index},[_c('span',{staticClass:"job_time"},[_vm._v(_vm._s(item.workStartDate)+"~"+_vm._s(item.workEndDate))]),_c('span',{staticClass:"job_con"},[_vm._v(" "+_vm._s(item.workContent)+" ")])])}),1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }